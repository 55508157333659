import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/global/Layout'
import SEO from '../components/global/Seo'
import TopNav from '../components/global/TopNav'
import VideoPlayer from '../components/global/VideoPlayer'
import ArrowIcon from '../components/icons/ArrowIcon'

const animation = ({data}) => {
  const {animation_title, animation_url, animation_still, section} = data.strapiAnimation;
  let sectionChapter;
  data.allStrapiChapter.nodes.forEach((chapter) => {
    if (chapter.strapiId === section.chapter) {
      sectionChapter = chapter;
    }
  })

  const sectionLink = `/${sectionChapter.chapter_slug}/${section.section_slug}/`;

  return (
		<Layout>
      <SEO title={animation_title} image={animation_still}/>
      <TopNav />
      <div className={`pt64 backgroundBlack`}>
        <div className={`pt64 pageMargin1Col`}>
          <Link to={'/animation-gallery/'} className={`h5`}>
            <i className={`inlineIcon mr8`} style={{transform: 'rotate(90deg)'}}>
              <ArrowIcon />
            </i>
            All Animations
          </Link>
          <h1 className={`h3 textCenter pb32 pt64`}>{animation_title}</h1>
        </div>
        <div className={`pageMarginRatio`}>
          <VideoPlayer url={animation_url} coverImage={animation_still} />
        </div>
        <div className={`pb64 pt32 textCenter pageMargin1Col`}>
          <p className={`h5 pb32`}>From the Under One Flag Online Exhibition</p>
          <Link to={sectionLink} className={`h5`}>
            <p className={`h4 textColorWhite`}>{section.section_title}</p>
            <p className={`h5 pb16 textColorWhite`}>
              <span className={`accentText`}>{sectionChapter.subtitle}: </span>
              {sectionChapter.title}
            </p>
            View In Section
            <i className={`inlineIcon pl4 pr4`} style={{transform: 'rotate(-90deg)'}}>
              <ArrowIcon />
            </i>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AnimationTemplate($animation_slug: String!) {
    strapiAnimation(animation_slug: { eq: $animation_slug }) {
      animation_title
      animation_slug
      animation_url
      animation_still {
        alternativeText
        caption
        localFile {
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      section {
        chapter
        section_slug
        section_title
      }
    }
    allStrapiChapter {
      nodes {
        strapiId
        chapter_slug
        title
        subtitle
      }
    }
  }
`

export default animation
